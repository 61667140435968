import React, { FC } from "react";
import appConfig from "../../util/appConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "..";
import style from "./style.module.scss";

interface IAdminDetailPageHeader {
  onClick?: () => void;
  btnText?: string;
  width?: string;
  isBtnAvailable?: boolean;
}

const Back = () => {
  return (
    <svg
      width="5"
      height="10"
      viewBox="0 0 5 10"
      fill={appConfig.mainColor}
      xmlns="http://www.w3.org/2000/svg"
      className={style.svg}
    >
      <path
        d="M4.39598 0.833465C4.32898 0.835696 4.2654 0.865621 4.21876 0.916923L0.575383 4.80295C0.527142 4.8545 0.5 4.92439 0.5 4.99725C0.5 5.07012 0.527142 5.14 0.575383 5.19155L4.21876 9.07758C4.26631 9.13285 4.33297 9.165 4.40322 9.16661C4.47353 9.16822 4.54144 9.13917 4.59116 9.08613C4.64089 9.03309 4.66814 8.96066 4.6666 8.88569C4.66507 8.81072 4.63496 8.73966 4.58312 8.68898L1.12193 4.99725L4.58312 1.30553C4.63308 1.25373 4.66119 1.1826 4.66119 1.10831C4.66119 1.03401 4.63296 0.962891 4.58301 0.911088C4.53306 0.859353 4.4656 0.831348 4.39599 0.833454L4.39598 0.833465Z"
        fill={appConfig.mainColor}
        stroke={appConfig.mainColor}
        strokeWidth="0.5"
      />
    </svg>
  );
};

export const AdminDetailPageHeader: FC<IAdminDetailPageHeader> = ({
  btnText,
  onClick,
  width,
  isBtnAvailable = true,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className={style.wrapper}>
      <div
        className={style.backItemWrapper}
        onClick={() => {
          // navigate(-1)
          const locationItems = location.pathname.split("/");
          locationItems.pop();

          navigate(locationItems.join("/"));
        }}
      >
        <div
          className={style.backWrapper}
          style={{
            border: `1px solid ${appConfig.mainColor}`,
          }}
        >
          <Back />
        </div>
        <div
          style={{
            color: appConfig.mainColor,
          }}
          className={style.backText}
        >
          Zurück
        </div>
      </div>
      {isBtnAvailable ? (
        <Button
          text={btnText ?? ""}
          width={width ? width : "250"}
          backgroundColor={"#008BD2"}
          textColor={"white"}
          onClick={handleClick}
        />
      ) : null}
    </div>
  );
};
